import style from "./blog.module.css";
import Semana1 from "./Semana1";
import Semana2 from "./Semana2";
import Semana3 from "./Semana3";
import Semana4 from "./Semana4";
import Semana5 from "./Semana5";
import Semana6 from "./Semana6";
import Semana7 from "./Semana7";
import Semana8 from "./Semana8";
import Semana9 from "./Semana9";
import Semana10 from "./Semana10";
import Semana11 from "./Semana11";
import Semana12 from "./Semana12";
import Semana13 from "./Semana13";
import Semana14 from "./Semana14";
import Semana15 from "./Semana15";
import Semana16 from "./Semana16";
import Semana17 from "./Semana17";
import Semana18 from "./Semana18";
import Semana19 from "./Semana19";
import Semana20 from "./Semana20";
import Semana21 from "./Semana21";
import Semana22 from "./Semana22";
import Semana23 from "./Semana23";
import Semana24 from "./Semana24";
import Semana25 from "./Semana25";

const Blog: React.FC = () => {
  //https://www.instagram.com/p/C25b0ygATVB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==
  return (
    <main className={style.MasterBoxBlog}>
      <Semana25 />
      <Semana24 />
      <Semana23 />
      <Semana22 />
      <Semana21 />
      <Semana20 />
      <Semana19 />
      <Semana18 />
      <Semana17 />
      <Semana16 />
      <Semana15 />
      <Semana14 />
      <Semana13 />
      <Semana12 />
      <Semana11 />
      <Semana10 />
      <Semana9 />
      <Semana8 />
      <Semana7 />
      <Semana6 />
      <Semana5 />
      <Semana4 />
      <Semana3 />
      <Semana2 />
      <Semana1 />
    </main>
  );
};

export default Blog;
