import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana25: React.FC = () => {
  const Notice1 = `Habitantes del sector del corregimiento Los Andes, zona rural de Cali, realizaron una denuncia ciudadana donde expusieron una tala ilegal de especies arbóreas con fines de explotación comercial, generando afectación a uno de los ecosistemas más importantes de la ciudad de Cali como lo es el Parque Natural Nacional Los Farallones de Cali.

Ante ese panorama, el concejal del Partido Alianza Verde, Rodrigo Salazar se desplazó al sector y constató la veracidad de los hechos, encontrando el impacto de tala, quema, huellas de motosierra, aceites inflamables, aserradero y residuos sólidos como cajetillas de cigarrillo, empaques de frituras, plásticos, latas y fósforos, que confirman la configuración de delitos contra el medio ambiente respecto de los cuales la autoridad ambiental DAGMA y la propia Alcaldía de Cali han sido omisivas. 

“Un programa tan importante como los guardabosques no ha respondido con diligencia y cuidado en la salvaguarda de esta zona de reserva.  Se afecta el equilibrio ecosistémico especialmente los acuíferos, desplazamiento de aves y fauna terrestre, comprometiendo el patrimonio ambiental de la ciudad, denuncia que se suma a otras relacionadas con explotación minera y ocupaciones irregulares”, manifestó el concejal.

El cabildante también constató que los implicados están quemando los troncos de grandes árboles para debilitarlos y facilitar posteriormente su tala, han adecuado un aserradero en la montaña con motosierras y caminos de herradura para bajar la madera a lomo de caballo, desde los puntos por donde dejan rodar la madera desde La Pinera en la cima del cerro.

Según el DAGMA, la ciudad de Cali presentó un déficit arbóreo de aproximadamente 450 mil árboles en el 2024, especialmente en el oriente, en donde las olas de calor se sienten con mayor intensidad. 

La comunidad denunciante señaló que la madera que está siendo extraída está siendo vendida para realizar adecuaciones en viviendas como construcción de techos, columnas y soportes, ósea explotación de recursos naturales con fines lucrativos.

El concejal Salazar desde las plenarias extraordinarias que se están llevando a cabo, hizo un llamado a la Personería Distrital de Cali y a la Procuraduría para que inicien las investigaciones y acciones disciplinarias y judiciales que correspondan y encontrar a los responsables de las omisiones en la conservación del ecosistema boscoso y la complicidad en la explotación de madera en zona de reserva forestal que hace parte del Parque Nacional Natural los Farallones de Cali e igualmente poder judicializar a los responsables de esta actividad delictiva y antisocial.
El cabildante reiteró la corresponsabilidad entre la Alcaldía de Cali, en cabeza de Alejandro Eder y el DAGMA, exigiendo resultados a estos organismos que desde la COP 16 se ha visto seriamente debilitado con grandes recortes presupuestales y presunta negligencia.

“Cali es la capital de la biodiversidad y requiere que toda su ciudadanía esté muy atenta y respondiente ante la protección de nuestro patrimonio ambiental, porque nos hace únicos en el mundo y garantiza nuestro suministro de agua potable, aire limpio, alimentos y una basta variedad de especies y microclimas”, expresó el concejal.

A las presiones de minería y asentamientos ilegales, se suma ahora una amenaza criminal que, bajo el dosier de los árboles, lucra a particulares con sus delitos contra el medio ambiente.

  `;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/reel/DFLVq6HSwLt/?igsh=ZTdvejNwb3FnbHJ6"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Concejal de Cali denuncia tala y venta de madera ilegal en zona de
          reserva forestal protegido por el DAGMA{" "}
        </h1>
        <h5 className={style.Fecha}>Enero 23 del 2025</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde; Proyectó: Janner Rodríguez Losada{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana25;
