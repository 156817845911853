import Navbar from "../Navbar";
import Footer from "../Footer";
import Style from "./template.module.css";
// import NavidadNieve from "../NavidadNieve";

interface Props {
  children: React.ReactNode;
}

const Template: React.FC<Props> = ({ children }) => {
  return (
    <main>
      <Navbar />
      {/* <NavidadNieve /> */}
      <div className={Style.BodyContent}>{children}</div>
      <Footer />
    </main>
  );
};

export default Template;
